@import '@{variablesLocation}';

.thule-checkbox {
  input[type='checkbox'] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;

    &:checked + label > i {
      display: block;
    }
    &:focus + label:before {
      border-width: 2px;
    }

    & + label {
      color: @Gray80;
      line-height: 150%;
      cursor: pointer;
      position: relative;
      display: flex;
      &:before {
        content: '';
        height: @spacer5;
        width: @spacer5;
        min-width: @spacer5;
        margin-top: @spacer1;
        margin-right: 0.8rem;
        border: 1px solid @Gray80;
        border-radius: 2px;
      }

      a {
        text-decoration: underline;
      }
    }

    & + label > i {
      position: absolute;
      left: -4px;
      top: -2px;
      display: none;
    }
  }

  label {
    font-size: 14px;

    * {
      font-size: 14px;
    }
  }

  .supporting-text {
    padding: @spacer2 @spacer3 @spacer2 @spacer7;
    color: @Gray80;
  }

  .error-message {
    color: @Error100;
  }

  &.disabled {
    pointer-events: none;

    label {
      color: @Gray40 !important;

      &:before {
        border: 1px solid @Gray40 !important;
      }
    }
  }
}
