@import '@{variablesLocation}';
@import '/Foundation/Styling/code/thule/global/media-queries.less';

.popup {
  background-color: rgba(0, 0, 0, 0.5);

  .modal-dialog {
    width: 335px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    @media (min-width: @screen-lg) {
      width: 554px;
    }

    .modal-content {
      border-radius: 0;

      .close-btn-container {
        display: flex;
        justify-content: flex-end;

        .close-btn {
          min-height: 0;
          min-width: 24px;
          width: 24px;
          height: 24px;
        }
      }

      .modal-header {
        border: none;
      }
    }
  }
}
